/* eslint-disable no-undef */
import Script from 'next/script';
import { useEffect } from 'react';
import { usePathname } from 'next/navigation';

// Lib
import { PRODUCTION } from '@/lib/environments';

//hooks
import useUser from '@/utils/hooks/swr/useUser';
import { PDF_EXPORT } from '@/lib/routes/privateRoutes';

const GoogleTagManager = () => {
    const { user } = useUser();
    const pathname = usePathname();

    useEffect(() => {
        if (!user) {
            return;
        }

        // user exists & window.dataLayer => start profitWell script
        if (window?.dataLayer) {
            window.dataLayer.push({
                event: 'start_pw',
                pw_user_email: user?.email ?? ''
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.email]);

    if (process.env.NEXT_PUBLIC_ENV !== PRODUCTION || pathname === PDF_EXPORT) return null;

    return (
        <Script id="google-tag-manager-script">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process?.env?.NEXT_PUBLIC_KEY_GOOGLE_TAG_MANAGER}');`}
        </Script>
    );
};

export default GoogleTagManager;
