import { useContext, useMemo, useReducer, createContext } from 'react';

export const UPDATE_SIGN_UP_STEP = 'UPDATE_SIGN_UP_STEP';
export const UPDATE_ACTIVE_SOCIAL_PROFILE = 'UPDATE_ACTIVE_SOCIAL_PROFILE';
export const UPDATE_ACTIVE_GROUP = 'UPDATE_ACTIVE_GROUP';
export const INITIAL_ACTIVE_GROUP = 'INITIAL_ACTIVE_GROUP';
export const UPDATE_ACTIVE_SOCIAL_PROFILE_IN_GROUP = 'UPDATE_ACTIVE_SOCIAL_PROFILE_IN_GROUP';
export const CLEAR_GROUP_INFO = 'CLEAR_GROUP_INFO';
export const TOGGLE_ACTIVE_MENU_GROUP = 'TOGGLE_ACTIVE_MENU_GROUP';
export const TOGGLE_MENU_SELECTED_GROUP_OPEN = 'TOGGLE_MENU_SELECTED_GROUP_OPEN';
export const FORCED_ACTIVE_GROUP = 'FORCED_ACTIVE_GROUP';
export const INIT_ADOBE_EXPRESS = 'INIT_ADOBE_EXPRESS';

import type { SocialProfileType } from '@/types/socialProfileType';
import type { ReactNode } from 'react';
import type { SpGroupType } from '@/hooks/swr/group/useSPGroups';
import type { CreateDesignOptionsType } from '@/types/external-library/adobeExpress/types';
import type { SessionSignUpStep } from '@/lib/signup/steps';

type InitialContextType = {
    signup: {
        currentStep: SessionSignUpStep;
    };
    social_profiles: {
        active: SocialProfileType;
    };
    group: {
        activeGroup: SpGroupType;
        activeSocialProfile: SocialProfileType;
    };
    menuGroupActive: boolean;
    menuSelectedGroupOpen: boolean;
    adobeExpress?: {
        createDesign: (data: CreateDesignOptionsType) => void;
    };
};

type GlobalInfoReducerActionsType =
    | {
          type: typeof UPDATE_SIGN_UP_STEP;
          payload: SessionSignUpStep;
      }
    | {
          type: typeof UPDATE_ACTIVE_SOCIAL_PROFILE | typeof UPDATE_ACTIVE_SOCIAL_PROFILE_IN_GROUP;
          payload: SocialProfileType;
      }
    | {
          type:
              | typeof UPDATE_ACTIVE_GROUP
              | typeof INITIAL_ACTIVE_GROUP
              | typeof FORCED_ACTIVE_GROUP;
          payload: SpGroupType;
      }
    | { type: typeof TOGGLE_ACTIVE_MENU_GROUP; payload: boolean }
    | { type: typeof TOGGLE_MENU_SELECTED_GROUP_OPEN; payload: boolean }
    | { type: typeof CLEAR_GROUP_INFO }
    | { type: typeof INIT_ADOBE_EXPRESS; payload: InitialContextType['adobeExpress'] };

export type Dispatch = (action: GlobalInfoReducerActionsType) => void;

const InitialContext: InitialContextType = {
    signup: {
        currentStep: null
    },
    social_profiles: {
        active: null
    },
    group: {
        activeGroup: null,
        activeSocialProfile: null
    },
    menuGroupActive: false,
    menuSelectedGroupOpen: false,
    adobeExpress: null
};

const GlobalInfoContext = createContext<{
    globalInfo: InitialContextType;
    dispatch: Dispatch;
}>(null);

const GlobalInfoReducer = (state: InitialContextType, action: GlobalInfoReducerActionsType) => {
    switch (action.type) {
        case UPDATE_SIGN_UP_STEP: {
            return {
                ...state,
                signup: {
                    ...state.signup,
                    currentStep: action?.payload ?? null
                }
            };
        }
        case UPDATE_ACTIVE_SOCIAL_PROFILE: {
            return {
                ...state,
                social_profiles: {
                    ...state.social_profiles,
                    active: action?.payload ?? null
                }
            };
        }
        case UPDATE_ACTIVE_GROUP: {
            return {
                ...state,
                social_profiles: {
                    ...state.social_profiles,
                    active: null
                },
                group: {
                    ...state.group,
                    activeGroup: action?.payload,
                    activeSocialProfile: null
                }
            };
        }
        case INITIAL_ACTIVE_GROUP: {
            return {
                ...state,
                social_profiles: {
                    ...state.social_profiles,
                    active: null
                },
                group: {
                    ...state.group,
                    activeGroup: action?.payload
                }
            };
        }
        case FORCED_ACTIVE_GROUP: {
            return {
                ...state,
                menuGroupActive: true,
                menuSelectedGroupOpen: true,
                social_profiles: {
                    ...state.social_profiles,
                    active: null
                },
                group: {
                    ...state.group,
                    activeGroup: action?.payload,
                    activeSocialProfile: null
                }
            };
        }
        case UPDATE_ACTIVE_SOCIAL_PROFILE_IN_GROUP: {
            if (action?.payload) {
                window.localStorage.setItem(
                    'ico-group-profiles-active',
                    JSON.stringify(action?.payload)
                );
            } else {
                window.localStorage.removeItem('ico-group-profiles-active');
            }

            return {
                ...state,
                social_profiles: {
                    ...state.social_profiles,
                    active: null
                },
                group: {
                    ...state.group,
                    activeSocialProfile: action?.payload ?? null
                }
            };
        }
        case CLEAR_GROUP_INFO: {
            window.localStorage.removeItem('ico-group-active');
            if (state.group.activeSocialProfile) {
                window.localStorage.removeItem('ico-social-profiles-active');
                window.localStorage.removeItem('ico-group-profiles-active');
            }
            return {
                ...state,
                group: InitialContext.group,
                menuSelectedGroupOpen: false
            };
        }
        case TOGGLE_ACTIVE_MENU_GROUP: {
            return {
                ...state,
                menuGroupActive: action.payload
            };
        }
        case TOGGLE_MENU_SELECTED_GROUP_OPEN: {
            return {
                ...state,
                menuSelectedGroupOpen: action.payload
            };
        }
        case INIT_ADOBE_EXPRESS: {
            return {
                ...state,
                adobeExpress: action.payload
            };
        }
    }
};
type GlobalInfoProviderProps = {
    value?: Partial<InitialContextType>;
    children: ReactNode;
};
const GlobalInfoProvider = ({ value, children }: GlobalInfoProviderProps) => {
    const [state, dispatch] = useReducer(GlobalInfoReducer, { ...InitialContext, ...value });

    const values = useMemo(() => ({ globalInfo: state, dispatch }), [state, dispatch]);

    return <GlobalInfoContext.Provider value={values}>{children}</GlobalInfoContext.Provider>;
};
const useGlobalInfo = () => {
    const context = useContext(GlobalInfoContext);
    if (!context) {
        throw new Error('useGlobalInfo must be used within a GlobalInfoProvider');
    }
    return context;
};
export { GlobalInfoProvider, useGlobalInfo };
