/* eslint-disable no-undef */
import Script from 'next/script';
import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';

// Lib
import { PRODUCTION } from '@/lib/environments';

export const DataDogLogCollection = () => {
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_ENV !== PRODUCTION) {
            return;
        }

        datadogLogs.init({
            clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
            site: process.env.NEXT_PUBLIC_DATADOG_SITE,
            forwardErrorsToLogs: true,
            forwardConsoleLogs: 'all',
            sessionSampleRate: 100
        });
    }, []);

    if (process.env.NEXT_PUBLIC_ENV !== PRODUCTION) return null;

    return <Script id="data-dog-log-collection"></Script>;
};
