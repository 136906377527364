import Script from 'next/script';
import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

// Libs
import { ADOBE_EXPRESS } from '@/lib/acl/aclRightsList';

// Hooks
import useUser from '@/utils/hooks/swr/useUser';
import { useGlobalInfo } from '@/utils/hooks/context/useGlobalInfo';
import useAcl from '@/utils/hooks/user/useAcl';

export function AdobeExpress() {
    const { user } = useUser();
    const { globalInfo, dispatch } = useGlobalInfo();
    const { lang } = useTranslation();
    const { hasAcl } = useAcl();

    const [isLoaded, setIsLoaded] = useState(false);

    async function initializeAdobeExpress() {
        if (isLoaded && !globalInfo?.adobeExpress) {
            const ccEverywhere = await window.CCEverywhere.initialize(
                {
                    clientId: process.env.NEXT_PUBLIC_ADOBE_EXPRESS_CLIENT_ID,
                    appName: 'Iconosquare'
                },
                { locale: `${lang}_${lang.toUpperCase()}` }
            );
            dispatch({
                type: 'INIT_ADOBE_EXPRESS',
                payload: ccEverywhere
            });
        }
    }

    useEffect(() => {
        initializeAdobeExpress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded]);

    if (!user || !hasAcl(ADOBE_EXPRESS.read)) return null;

    return (
        <Script
            src="https://sdk.cc-embed.adobe.com/v3/CCEverywhere.js"
            onLoad={() => setIsLoaded(true)}
        />
    );
}
