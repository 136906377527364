import useUser from '@/hooks/swr/useUser';

const useAcl = () => {
    const { user } = useUser();

    const hasAcl = (permissions: string | string[]) => {
        if (typeof permissions === 'string') {
            return user?.permissions?.includes(permissions);
        } else if (Array.isArray(permissions)) {
            return (
                permissions.filter((permission) => user?.permissions?.includes(permission))
                    .length === permissions.length
            );
        }

        return false;
    };

    return { hasAcl };
};

export default useAcl;
