import { useCallback, useMemo } from 'react';

// Hooks
import useSPGroups from '@/hooks/swr/group/useSPGroups';
import {
    useGlobalInfo,
    UPDATE_ACTIVE_GROUP,
    INITIAL_ACTIVE_GROUP,
    UPDATE_ACTIVE_SOCIAL_PROFILE_IN_GROUP,
    FORCED_ACTIVE_GROUP,
    CLEAR_GROUP_INFO
} from '@/hooks/context/useGlobalInfo';
import useActiveTreeStructure from '@/utils/hooks/tree-structure/useActiveTreeStructure';

// Types
import type { SpGroupType } from '@/hooks/swr/group/useSPGroups';

// Store
import { useProfilesStore } from '@/utils/zustand/providers/profiles-provider';

const useOldActiveGroup = () => {
    const { globalInfo, dispatch } = useGlobalInfo();

    const activeGroupId = useMemo(() => {
        if (globalInfo.group.activeGroup?.id) {
            return globalInfo.group.activeGroup.id;
        }
        return null;
    }, [globalInfo.group.activeGroup?.id]);

    const { spGroups } = useSPGroups({
        revalidateOnMount: false,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });
    const activeGroup: SpGroupType = useMemo(() => {
        if (spGroups?.length > 0 && activeGroupId) {
            return spGroups.find((group) => group.id === activeGroupId);
        }
        return null;
    }, [activeGroupId, spGroups]);

    const setActiveGroup = useCallback(
        (group: SpGroupType) => {
            if (activeGroupId !== group.id) {
                dispatch({
                    type: UPDATE_ACTIVE_GROUP,
                    payload: group
                });
                localStorage.setItem('ico-group-active', JSON.stringify(group));
            }
        },
        [activeGroupId, dispatch]
    );

    const removeActiveSocialProfileFromGroup = useCallback(
        (comparisonData: SpGroupType['social_profiles']) => {
            if (globalInfo.group?.activeSocialProfile) {
                const activeProfile = comparisonData.find(
                    (socialProfile) => socialProfile.id === globalInfo.group.activeSocialProfile.id
                );

                if (!activeProfile || !activeProfile.active) {
                    dispatch({
                        type: UPDATE_ACTIVE_SOCIAL_PROFILE_IN_GROUP,
                        payload: null
                    });
                }
            }
        },
        [dispatch, globalInfo.group.activeSocialProfile]
    );

    const removeActiveGroupIfNoProfile = useCallback(() => {
        dispatch({
            type: CLEAR_GROUP_INFO
        });
        localStorage.removeItem('ico-group-active');
    }, [dispatch]);

    const updateGroupDataAndStorage = useCallback(
        (data: SpGroupType) => {
            localStorage.setItem('ico-group-active', JSON.stringify(data));
            dispatch({ type: INITIAL_ACTIVE_GROUP, payload: data });
        },
        [dispatch]
    );

    const forcedActiveGroup = useCallback(
        (data: SpGroupType) => {
            dispatch({
                type: FORCED_ACTIVE_GROUP,
                payload: data
            });
            localStorage.setItem('ico-group-active', JSON.stringify(data));
        },
        [dispatch]
    );

    return {
        oldActiveGroup: activeGroup,
        setOldActiveGroup: setActiveGroup,
        updateOldGroupDataAndStorage: updateGroupDataAndStorage,
        removeOldActiveSocialProfileFromGroup: removeActiveSocialProfileFromGroup,
        removeOldActiveGroupIfNoProfile: removeActiveGroupIfNoProfile,
        forcedOldActiveGroup: forcedActiveGroup
    };
};

const useNewActiveGroup = () => {
    const {
        activeSocialProfileId,
        activeGroupId,
        manageGroups,
        resetActiveGroup,
        resetActiveSocialProfile
    } = useProfilesStore((state) => state);

    const { spGroups } = useSPGroups({
        revalidateIfStale: false
    });

    const activeGroup: SpGroupType = useMemo(() => {
        if (spGroups?.length && activeGroupId) {
            return spGroups.find((group) => group.id === activeGroupId);
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeGroupId, JSON.stringify(spGroups)]);

    const setActiveGroup = useCallback(
        (group: SpGroupType) => manageGroups(group),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeGroupId]
    );

    const removeActiveSocialProfileFromGroup = useCallback(
        (comparisonData: SpGroupType['social_profiles']) => {
            if (activeSocialProfileId) {
                const activeProfile = comparisonData.find(
                    (socialProfile) => socialProfile.id === activeSocialProfileId
                );

                if (!activeProfile?.active) {
                    resetActiveSocialProfile();
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeSocialProfileId]
    );

    return {
        newActiveGroup: activeGroup,
        setNewActiveGroup: setActiveGroup,
        resetActiveGroup,
        removeNewActiveSocialProfileFromGroup: removeActiveSocialProfileFromGroup
    };
};

const useActiveGroup = () => {
    const { isTreeStructureActivated } = useActiveTreeStructure();
    const {
        oldActiveGroup,
        setOldActiveGroup,
        updateOldGroupDataAndStorage,
        removeOldActiveSocialProfileFromGroup,
        removeOldActiveGroupIfNoProfile,
        forcedOldActiveGroup
    } = useOldActiveGroup();

    const {
        newActiveGroup,
        setNewActiveGroup,
        resetActiveGroup,
        removeNewActiveSocialProfileFromGroup
    } = useNewActiveGroup();

    return {
        activeGroup: isTreeStructureActivated ? newActiveGroup : oldActiveGroup,
        setActiveGroup: isTreeStructureActivated ? setNewActiveGroup : setOldActiveGroup,
        updateGroupDataAndStorage: isTreeStructureActivated
            ? setNewActiveGroup
            : updateOldGroupDataAndStorage,
        removeActiveSocialProfileFromGroup: isTreeStructureActivated
            ? removeNewActiveSocialProfileFromGroup
            : removeOldActiveSocialProfileFromGroup,
        removeActiveGroupIfNoProfile: isTreeStructureActivated
            ? resetActiveGroup
            : removeOldActiveGroupIfNoProfile,
        forcedActiveGroup: isTreeStructureActivated ? setNewActiveGroup : forcedOldActiveGroup
    };
};

export default useActiveGroup;
