export const ADMIN_TRANSFER = {
    update: 'admin_transfer.update'
} as const;

export const ADS = {
    read: 'ads.read'
} as const;

export const BILLING = {
    read: 'billing.read',
    update: 'billing.update'
} as const;

export const COLLABORATION = {
    read: 'collaboration.read'
} as const;

export const COMPANY_LOGO = {
    update: 'company_logo.update'
} as const;

export const COMPETITORS = {
    create: 'competitors.create',
    delete: 'competitors.delete'
} as const;

export const DASHBOARD = {
    read: 'dashboard.read'
} as const;

export const DATA_ACCESS = {
    read: 'data_access.read'
} as const;

export const DIRECT_MESSAGES = {
    read: 'direct_messages.read',
    create: 'direct_messages.create',
    update: 'direct_messages.update'
} as const;

export const FEEDS = {
    update: 'feeds.update',
    create: 'feeds.create',
    delete: 'feeds.delete'
} as const;

export const HASHTAGS = {
    create: 'hashtags.create',
    delete: 'hashtags.delete'
} as const;

export const MENTIONS = {
    read: 'mentions.read'
} as const;

export const PDF_EXPORT = {
    create: 'pdf_export.create'
} as const;

export const SOCIAL_PROFILES = {
    create: 'social_profiles.create',
    delete: 'social_profiles.delete'
} as const;

export const USER_INVITATIONS = {
    create: 'user_invitations.create',
    update: 'user_invitations.update',
    delete: 'user_invitations.delete'
} as const;

export const TEAM_MEMBERS = {
    update: 'team_members.update',
    delete: 'team_members.delete'
} as const;

export const USER_DELETE = {
    delete: 'user.delete'
} as const;

export const DASHBOARD_REPORT_CUSTOMIZATION = {
    read: 'dashboard_report_customization.read'
} as const;

export const TIKTOK_PUBLISHING_HAS_ACCESS = {
    read: 'tiktok_publishing_has_access.read'
} as const;

export const NEW_PRICING = {
    read: 'new_pricing.read'
} as const;

//TODO: remove this when cloudfront test is done
export const CLOUDFRONT_TEST = {
    read: 'cloudfront_test.read'
} as const;

export const INTEGRATION_MANAGE_KEYS = {
    read: 'integration_manage_keys.read'
} as const;

export const PUBLIC_API = {
    read: 'public_api.read'
} as const;

export const ZAPIER_API = {
    read: 'zapier_api.read'
} as const;

//TODO: remove this when review adobe express is done
export const ADOBE_EXPRESS = {
    read: 'adobe_express.read'
} as const;

export const TREE_STRUCTURE = {
    read: 'tree_structure.read'
};

export const YOUTUBE_ACCESS = {
    read: 'youtube.read'
};

export const PINTEREST_ACCESS = {
    read: 'pinterest.read'
};

export const SCHEDULER_NEW_FUNNEL = {
    read: 'scheduler_new_funnel.read'
};
