'use client';

import { createContext, useRef, useContext } from 'react';
import { useStore } from 'zustand';

import { createToggleLayoutPlatform } from '@/utils/zustand/stores/toggle-layout-platform-store';

// Types
import type { StoreApi } from 'zustand';
import type { ToggleLayoutPlatformType } from '@/utils/zustand/stores/toggle-layout-platform-store';

const ToggleLayoutPlatformContext = createContext(null);

export type ToggleLayoutPlatformProviderProps = {
    children: React.ReactNode;
};

export const ToggleLayoutPlatformProvider = ({ children }: ToggleLayoutPlatformProviderProps) => {
    const storeRef = useRef<StoreApi<ToggleLayoutPlatformType>>(null); // Add null as initial value

    if (!storeRef.current) {
        storeRef.current = createToggleLayoutPlatform();
    }

    return (
        <ToggleLayoutPlatformContext.Provider value={storeRef.current}>
            {children}
        </ToggleLayoutPlatformContext.Provider>
    );
};

export const useToggleLayoutPlatform = <T,>(
    selector: (store: ToggleLayoutPlatformType) => T
): T => {
    const toggleLayoutPlatformContext = useContext(ToggleLayoutPlatformContext);

    if (!toggleLayoutPlatformContext) {
        throw new Error(`useToggleLayoutPlatform must be use within ToggleLayoutPlatformProvider`);
    }

    return useStore(toggleLayoutPlatformContext, selector);
};
