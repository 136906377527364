import { createStore } from 'zustand/vanilla';
import { immer } from 'zustand/middleware/immer';

// Types
import { SIGN_UP } from '@/lib/routes/privateRoutes';
import { UserType } from '@/types/userType';
import { setSignUpStepLocalStorage } from '@/utils/localStorage/signUpStepLocalStorage';

export type SignUpStepType = keyof typeof SIGN_UP | 'SIGN_UP_DONE';

export type SignUpStoreStateType = {
    currentStep: SignUpStepType;
};

export type SignUpStoreActionsType = {
    updateCurrentStep: (step: SignUpStepType, userId: UserType['id']) => void;
};

export type SignUpStoreType = SignUpStoreStateType & SignUpStoreActionsType;

export const defaultInitState: SignUpStoreStateType = {
    currentStep: null
};

export const createSignUpStore = (initState: SignUpStoreStateType = defaultInitState) => {
    return createStore<SignUpStoreType>()(
        immer((set) => ({
            ...initState,
            updateCurrentStep: (step: SignUpStepType, userId) => {
                set((state) => {
                    state.currentStep = step;
                });
                setSignUpStepLocalStorage(step, userId);
            }
        }))
    );
};
