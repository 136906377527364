import useSWR from 'swr';

// Utils
import { get } from '@/utils/client';

// Types
import type { ErrorCustom } from '@/utils/client';
import type { SWRConfiguration } from 'swr';
import type { SocialProfileType } from '@/types/socialProfileType';

// API Endpoints
import { USER_SOCIAL_PROFILES_AUTH_STATUS } from '@/routes/apiEndpoints';

type SocialProfilesAuthStatusType = Record<
    SocialProfileType['id'],
    SocialProfileType['auth_status']
>;

function useSocialProfilesAuthStatus(
    options?: SWRConfiguration<SocialProfilesAuthStatusType, ErrorCustom>
) {
    const {
        data: socialProfilesAuthStatus,
        mutate: mutateSocialProfilesAuthStatus,
        error: socialProfilesAuthStatusError,
        isLoading
    } = useSWR<SocialProfilesAuthStatusType, ErrorCustom>(
        USER_SOCIAL_PROFILES_AUTH_STATUS,
        (url) => get(url).then((res) => res.data),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateIfStale: false,
            ...options
        }
    );
    return {
        socialProfilesAuthStatus,
        mutateSocialProfilesAuthStatus,
        socialProfilesAuthStatusError,
        isLoading
    };
}

export default useSocialProfilesAuthStatus;
