import type { DefaultSeoProps } from 'next-seo';

const config: DefaultSeoProps = {
    title: process.env.NEXT_PUBLIC_APP_NAME,
    canonical: process.env.NEXT_PUBLIC_BASE_URL,
    openGraph: {
        type: 'website',
        title: process.env.NEXT_PUBLIC_APP_NAME,
        url: process.env.NEXT_PUBLIC_BASE_URL,
        siteName: 'Iconosquare',
        images: [
            { url: `${process.env.NEXT_PUBLIC_CDN_URL}/images/share/iconosquare-preview-new.jpg` }
        ]
    },
    twitter: {
        handle: '@iconosquare',
        site: '@iconosquare',
        cardType: 'summary_large_image'
    },
    additionalMetaTags: [
        {
            property: 'author',
            content: 'Tripnity'
        },
        {
            property: 'X-UA-Compatible',
            content: 'IE=edge,chrome=1'
        },
        {
            property: 'msapplication-config',
            content: 'none'
        },
        {
            property: 'viewport',
            content:
                'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no'
        },
        {
            property: 'google-site-verification',
            content: 'vK-XhjlqrpOsspafHy8WLEdBz-s-_3rK10oXdwZuoyo'
        },
        // Pinterest meta
        {
            property: 'p:domain_verify',
            content: '051ac1ef4314a41721ba94ac06800219'
        },
        {
            property: 'apple-itunes-app',
            content: 'app-id=1150428618'
        },
        // Meta for Web app on IOS
        {
            property: 'apple-mobile-web-app-capable',
            content: 'yes'
        },
        {
            property: 'apple-mobile-web-app-status-bar-style',
            content: 'black-translucent'
        },
        {
            property: 'apple-mobile-web-app-title',
            content: 'Iconosquare'
        }
    ],
    additionalLinkTags: [
        {
            rel: 'manifest',
            href: `${process.env.NEXT_PUBLIC_CDN_URL}/manifest.json`
        },
        {
            rel: 'shortcut icon',
            href: `${process.env.NEXT_PUBLIC_CDN_URL}/images/favicons/favicon-16.ico`
        },
        {
            rel: 'icon',
            sizes: '16x16 32x32',
            href: `${process.env.NEXT_PUBLIC_CDN_URL}/images/favicons/favicon-32.png`
        },
        {
            rel: 'apple-touch-icon-precomposed',
            sizes: '57x57',
            href: `${process.env.NEXT_PUBLIC_CDN_URL}/images/favicons/favicon-57.png`
        },
        {
            rel: 'apple-touch-icon-precomposed',
            sizes: '72x72',
            href: `${process.env.NEXT_PUBLIC_CDN_URL}/images/favicons/favicon-72.png`
        },
        {
            rel: 'apple-touch-icon-precomposed',
            sizes: '114x114',
            href: `${process.env.NEXT_PUBLIC_CDN_URL}/images/favicons/favicon-114.png`
        },
        {
            rel: 'apple-touch-icon-precomposed',
            sizes: '120x120',
            href: `${process.env.NEXT_PUBLIC_CDN_URL}/images/favicons/favicon-120.png`
        },
        {
            rel: 'apple-touch-icon-precomposed',
            href: `${process.env.NEXT_PUBLIC_CDN_URL}/images/favicons/favicon-152.png`
        }
    ]
};

export default config;
