// Types
import type { UserType } from '@/types/userType';
import type { SignUpStepType } from '@/utils/zustand/stores/sign-up-store';

const signUpStepLocalStorageKey = 'local-storage-sign-up-step';

export function setSignUpStepLocalStorage(step: SignUpStepType, userId: UserType['id']) {
    window.localStorage.setItem(`${signUpStepLocalStorageKey}-${userId}`, JSON.stringify(step));
}

export function getSignUpStepLocalStorage(userId: UserType['id']): SignUpStepType {
    return JSON.parse(window.localStorage.getItem(`${signUpStepLocalStorageKey}-${userId}`));
}
