import type { SocialProfileNetworkType } from '@/types/socialProfileType';

export function checkNetworkInList(
    network: SocialProfileNetworkType,
    list: SocialProfileNetworkType[]
) {
    return list.includes(network);
}
export function checkNetworkNotInList(
    network: SocialProfileNetworkType,
    list: SocialProfileNetworkType[]
) {
    return !list.includes(network);
}
