import useSWR from 'swr';
import { useMemo } from 'react';
import type { SWRConfiguration, KeyedMutator } from 'swr';
import type { AxiosError } from 'axios';

// Hooks
import useUser from '@/hooks/swr/useUser';
import useSocialProfiles from '@/hooks/swr/useSocialProfiles';

// Utils
import { get } from '@/utils/client';
import { sortProfilesByActive } from '@/utils/socialProfiles/sortProfilesByActive';

// Types
import type { SocialProfileType } from '@/types/socialProfileType';

// Routes
import { SP_GROUPS } from '@/routes/apiEndpoints';

export type SpGroupType = {
    id: number;
    name: string;
    image?: string;
    fallback_style: string;
    active: boolean;
    social_profiles: SocialProfileType[];
};

export type dataSpGroupsType = {
    data: SpGroupType[];
};

export type mutateSpGroupsType = KeyedMutator<dataSpGroupsType>;

const useSPGroups = (options?: SWRConfiguration) => {
    const { user } = useUser();
    const { socialProfiles } = useSocialProfiles({ revalidateIfStale: false });

    const {
        data,
        mutate: mutateSpGroups,
        error: errorSpGroups,
        isLoading
    } = useSWR<dataSpGroupsType, AxiosError<Error>>(
        user && user?.sign_up_step === 'sign_up_done' ? SP_GROUPS : null,
        (url) => get(url).then((res) => res.data),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            ...options
        }
    );

    //sort groups by active
    const spGroups = useMemo(
        () => {
            const groups =
                data?.data.map((group) => {
                    if (!socialProfiles) {
                        return {
                            ...group,
                            social_profiles: sortProfilesByActive(group.social_profiles)
                        };
                    }

                    const social_profiles = group.social_profiles.map((profile) => {
                        return socialProfiles.find((sp) => sp.id === profile.id);
                    });

                    return {
                        ...group,
                        social_profiles: sortProfilesByActive(social_profiles)
                    };
                }) ?? [];

            return [...groups]?.sort((group) => (group.active ? -1 : 1));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(socialProfiles), data?.data]
    );

    const activatedSpGroups = useMemo(
        () => spGroups.filter((spGroup) => spGroup.active),
        [spGroups]
    );

    return {
        spGroups,
        activatedSpGroups,
        mutateSpGroups,
        errorSpGroups,
        isLoading
    };
};
export default useSPGroups;
