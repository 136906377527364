/* eslint-disable no-undef */
import Script from 'next/script';
import { useCallback, useEffect, useMemo } from 'react';

// Hooks
import useActiveSocialProfile from '@/utils/hooks/user/useActiveSocialProfile';
import useActiveGroup from '@/utils/hooks/user/useActiveGroup';
import useUserPendo from '@/utils/hooks/swr/useUserPendo';

export const PendoScript = () => {
    return (
        <Script id="pendo-script">
            {`(function(apiKey){
                (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
                v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
                    o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                    y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
                    z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
            })('${process?.env?.NEXT_PUBLIC_KEY_PENDO}');`}
        </Script>
    );
};

export const PendoInitialize = () => {
    const { userPendo } = useUserPendo({
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });

    const { activeSocialProfile } = useActiveSocialProfile();
    const { activeGroup } = useActiveGroup();
    const pendoVisitor = JSON.stringify(userPendo?.pendo_visitor ?? {});
    const pendoAccount = JSON.stringify(userPendo?.pendo_account ?? {});

    //annotatePendoUrl
    const pendoAnnotatedUrl = useMemo(() => {
        const currentSocialNetwork = activeSocialProfile?.social_network_type ?? 'none';
        const hasActiveGroup = Boolean(
            activeGroup?.id && !activeSocialProfile?.social_network_type
        );
        return {
            pendo_sp: hasActiveGroup ? 'group' : currentSocialNetwork
        };
    }, [activeSocialProfile, activeGroup]);

    const handleAnnotation = useCallback(() => pendoAnnotatedUrl, [pendoAnnotatedUrl]);

    useEffect(() => {
        if (!userPendo || userPendo?.auth_as) {
            return undefined;
        }

        const dataPendoVisitor = userPendo?.pendo_visitor ?? {};
        const dataPendoAccount = userPendo?.pendo_account ?? {};

        if (dataPendoVisitor?.id && dataPendoAccount?.id && typeof pendo === 'object') {
            const currentSocialNetwork = activeSocialProfile?.social_network_type ?? 'none';
            const dataToSend = {
                visitor: {
                    ...dataPendoVisitor,
                    icoTypeAccount: currentSocialNetwork,
                    language: navigator?.language ?? 'en-US',
                    dataSpActiveLoaded:
                        activeSocialProfile?.first_stats ?? activeSocialProfile?.full_stats ?? false
                },
                account: dataPendoAccount,
                annotateUrl: handleAnnotation
            };

            if (typeof pendo?.getAccountId === 'function' && pendo?.getAccountId()) {
                pendo?.updateOptions(dataToSend);
            } else {
                pendo?.initialize(dataToSend);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        userPendo?.auth_as,
        activeSocialProfile?.social_network_type,
        activeSocialProfile?.first_stats,
        activeSocialProfile?.full_stats,
        pendoVisitor,
        pendoAccount,
        pendoAnnotatedUrl
    ]);

    return null;
};

export const pendoTrack = (name = '') => {
    if (
        typeof pendo === 'object' &&
        pendo &&
        pendo.isReady &&
        pendo.isReady() &&
        typeof pendo.track === 'function'
    ) {
        pendo.track(name);
    }
};
