import Cookies from 'js-cookie';
import { useEffect } from 'react';

const oldCookiesRegex: RegExp[] = [
    /^network-suggested-.*/,
    /^ico-datepicker-.*/,
    /^scheduler.*/,
    /^ico-theme$/,
    /^ico-language$/,
    /^surprise_me$/,
    /^linkedin.*$/,
    /^feed_preview_media_library_active$/,
    /^album_comparative_table.*$/,
    /^group_.*$/,
    /^new-feature-notifications$/,
    /^scheduler_notify_option$/,
    /^instagram.*$/,
    /^facebook.*$/,
    /^inspiration$/,
    /^tiktok.*$/,
    /^autopost_story_scheduler$/
];

const deleteOldCookiesLocalStorage = {
    key: 'delete_old_cookies',
    value: '1'
};

const DeleteOldCookies = () => {
    const deleteOldCookies = () => {
        if (
            localStorage.getItem(deleteOldCookiesLocalStorage.key) !==
            deleteOldCookiesLocalStorage.value
        ) {
            Object.keys(Cookies.get()).forEach((cookieName: string) => {
                if (oldCookiesRegex.some((regex) => regex.test(cookieName))) {
                    Cookies.remove(cookieName);
                }
            });
            localStorage.setItem(
                deleteOldCookiesLocalStorage.key,
                deleteOldCookiesLocalStorage.value
            );
        }
    };

    useEffect(() => {
        deleteOldCookies();
    }, []);

    return null;
};

export default DeleteOldCookies;
