import { createStore } from 'zustand/vanilla';
import { immer } from 'zustand/middleware/immer';

export type ToggleLayoutPlatformStateType = {
    isActive: boolean;
};

export type ToggleLayoutPlatformActionsType = {
    updatePlatformLayout: (arg: boolean) => void;
};

export type ToggleLayoutPlatformType = ToggleLayoutPlatformStateType &
    ToggleLayoutPlatformActionsType;

export const defaultInitState: ToggleLayoutPlatformStateType = {
    isActive: false
};

export const createToggleLayoutPlatform = (
    initState: ToggleLayoutPlatformStateType = defaultInitState
) => {
    return createStore<ToggleLayoutPlatformType>()(
        immer((set) => ({
            ...initState,
            updatePlatformLayout: (arg) => {
                set((state) => {
                    state.isActive = arg;
                });
            }
        }))
    );
};
