'use client';

import { createContext, useRef, useContext } from 'react';
import { useStore } from 'zustand';

import { createProfilesStore } from '@/utils/zustand/stores/profiles-store';

// Types
import type { StoreApi } from 'zustand';
import type { ProfilesStoreType } from '@/utils/zustand/stores/profiles-store';

const ProfilesStoreContext = createContext(null);

export type ProfilesStoreProviderProps = {
    children: React.ReactNode;
};

export const ProfilesStoreProvider = ({ children }: ProfilesStoreProviderProps) => {
    const storeRef = useRef<StoreApi<ProfilesStoreType>>(null); // Add null as initial value

    if (!storeRef.current) {
        storeRef.current = createProfilesStore();
    }

    return (
        <ProfilesStoreContext.Provider value={storeRef.current}>
            {children}
        </ProfilesStoreContext.Provider>
    );
};

export const useProfilesStore = <T,>(selector: (store: ProfilesStoreType) => T): T => {
    const profilesStoreContext = useContext(ProfilesStoreContext);

    if (!profilesStoreContext) {
        throw new Error(`useProfilesStore must be use within ProfilesStoreProvider`);
    }

    return useStore(profilesStoreContext, selector);
};
