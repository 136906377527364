import { useRouter } from 'next/router';
import queryString from 'query-string';

// Types
import type { Router } from 'next/router';

const useRouterQuery = () => {
    const router = useRouter();

    const formatUrlWithQuery = (url: string, queries: Router['query']) => {
        if (queries && Object.entries(queries)?.length) {
            return `${url}?${queryString.stringify(queries, { arrayFormat: 'bracket' })}`;
        }
        return url;
    };

    const deleteQuery = (query: string | string[] = '') => {
        const url = router.asPath.split('?')[0];
        const queries = router.query;
        if (typeof query === 'string') {
            delete queries[query];
        } else if (Array.isArray(query)) {
            query.forEach((item: string) => {
                delete queries[item];
            });
        }

        return formatUrlWithQuery(url, queries);
    };

    const deleteAllQueries = () => {
        const url = router.asPath.split('?')[0];
        return url;
    };

    const addQueryParams = (params: string[], options: Parameters<Router['push']>[2] = {}) => {
        const baseUrl = router.asPath.split('?')[0];
        const formattedQuery = params.map((param) => `?${param}`);
        router.push(`${baseUrl}${formattedQuery.join('')}`, undefined, {
            ...options,
            shallow: true
        });
    };

    const clearUrlQueriesWithoutReload = () => {
        router.replace(deleteAllQueries(), undefined, { shallow: true });
    };

    return {
        formatUrlWithQuery,
        deleteQuery,
        deleteAllQueries,
        addQueryParams,
        clearUrlQueriesWithoutReload
    };
};

export default useRouterQuery;
