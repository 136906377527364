import { USER_PENDO } from '@/routes/apiEndpoints';
import { get } from '@/utils/client';
import useSWR from 'swr';

//types
import type { PlanFrequencyType, PlanType } from '@/types/pricingTypes/planType';
import type { PlatformLanguagesType, UserType } from '@/types/userType';
import type { SWRConfiguration } from 'swr';

type UserPendo = {
    auth_as: boolean;
    pendo_account: {
        email?: string;
        id: string;
        planLevel: Exclude<PlanType, 'old'>;
        startPlan?: string;
        billingFrequency: PlanFrequencyType;
    };
    pendo_visitor: {
        email?: string;
        icoLang: PlatformLanguagesType;
        id: string;
        full_name?: string;
        role: UserType['acl_role'];
    };
};

const useUserPendo = (options?: SWRConfiguration<UserPendo, Error>) => {
    const {
        data: userPendo,
        mutate: mutateUserPendo,
        error: userPendoError,
        isLoading
    } = useSWR<UserPendo, Error>(USER_PENDO, (url) => get(url).then((res) => res.data), {
        shouldRetryOnError: false,
        ...options
    });

    return {
        userPendo,
        mutateUserPendo,
        userPendoError,
        isLoading
    };
};
export default useUserPendo;
