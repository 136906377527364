import { useEffect } from 'react';
import { dequal } from 'dequal';
import mixpanel from 'mixpanel-browser';

//hooks
import useUserPendo from '@/utils/hooks/swr/useUserPendo';
import { useLoggedInStore } from '@/utils/zustand/providers/logged-in-provider';
import useUser from '@/utils/hooks/swr/useUser';

export function IsUserLoggedIn() {
    const { userPendo } = useUserPendo({
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });
    const { user } = useUser();

    const { isLoggedIn, updateIsLoggedIn } = useLoggedInStore((state) => state);

    useEffect(() => {
        if (isLoggedIn || !userPendo || dequal(userPendo.pendo_account, {}) || !user) {
            return;
        }
        // trigger mixpanel login event
        mixpanel?.track('mixpanel_login_completed');
        //maj store
        updateIsLoggedIn(true);
    }, [isLoggedIn, updateIsLoggedIn, userPendo, user]);

    useEffect(() => {
        if (!user && isLoggedIn) {
            // reset store when no user
            updateIsLoggedIn(false);
        }
    }, [isLoggedIn, updateIsLoggedIn, user]);

    return null;
}
