import useSWR from 'swr';
import { get } from '@/utils/client';
import { USER } from '@/routes/apiEndpoints';

import type { UserType } from '@/types/userType';
import type { ErrorCustom } from '@/utils/client';
import type { SWRConfiguration, KeyedMutator } from 'swr';

export type MutateUserType = KeyedMutator<UserType>;

const useUser = (options?: SWRConfiguration<UserType, ErrorCustom>) => {
    const {
        data,
        mutate: mutateUser,
        error: userError,
        isLoading
    } = useSWR<UserType, ErrorCustom>(USER, (url) => get(url).then((res) => res.data), {
        shouldRetryOnError: false,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        ...options
    });

    return {
        user: data,
        mutateUser,
        userError,
        isLoading
    };
};
export default useUser;
