import { createStore } from 'zustand/vanilla';
import { immer } from 'zustand/middleware/immer';

// Types
import type { SocialProfileType } from '@/types/socialProfileType';
import type { SpGroupType } from '@/utils/hooks/swr/group/useSPGroups';
import {
    deleteActiveGroupIdLocalStorage,
    deleteActiveMultiProfileIdsLocalStorage,
    deleteActiveProfileIdLocalStorage,
    setActiveGroupIdLocalStorage,
    setActiveMultiProfileIdsLocalStorage,
    setActiveProfileIdLocalStorage
} from '@/utils/localStorage/profilesLocalStorage';

export type ProfilesStoreStateType = {
    activeSocialProfileId: SocialProfileType['id'];
    activeGroupId: SpGroupType['id'];
    activeMultiSelectionProfiles: {
        socialProfileIds: SocialProfileType['id'][];
    };
};

export type ProfilesStoreActionsType = {
    manageProfiles: (profiles: SocialProfileType[]) => void;
    manageGroups: (group: SpGroupType) => void;
    updateActiveSocialProfileId: (profileId: SocialProfileType['id']) => void;
    updateActiveGroupId: (groupId: SpGroupType['id']) => void;
    updateActiveMultiProfileIds: (profileIds: SpGroupType['id'][]) => void;
    resetActiveSocialProfile: () => void;
    resetActiveGroup: () => void;
};

export type ProfilesStoreType = ProfilesStoreStateType & ProfilesStoreActionsType;

export const defaultInitState: ProfilesStoreStateType = {
    activeSocialProfileId: null,
    activeGroupId: null,
    activeMultiSelectionProfiles: null
};

export const createProfilesStore = (initState: ProfilesStoreStateType = defaultInitState) => {
    return createStore<ProfilesStoreType>()(
        immer((set) => ({
            ...initState,
            manageProfiles: (profiles) => {
                set((state) => {
                    // Clear active social group
                    state.activeGroupId = null;
                    deleteActiveGroupIdLocalStorage();

                    /**
                     * If only one profile is selected, set the activeSocialProfile entry
                     */
                    if (profiles.length === 1) {
                        // Clear active multi selection profiles
                        state.activeMultiSelectionProfiles = null;
                        deleteActiveMultiProfileIdsLocalStorage();

                        // Set active social profile
                        state.activeSocialProfileId = profiles[0].id;
                        setActiveProfileIdLocalStorage(profiles[0].id);

                        return;
                    }

                    /**
                     * If multi profiles are selected, set the activeMultiSelectionProfiles entry
                     * and clear the activeSocialProfile entry
                     */
                    if (
                        !state.activeSocialProfileId ||
                        (state.activeSocialProfileId &&
                            !profiles.find((profile) => profile.id === state.activeSocialProfileId))
                    ) {
                        state.activeSocialProfileId = profiles[0].id;
                        setActiveProfileIdLocalStorage(profiles[0].id);
                    }

                    const socialProfileIds = profiles.map((profile) => profile.id);

                    state.activeMultiSelectionProfiles = { socialProfileIds };
                    setActiveMultiProfileIdsLocalStorage(socialProfileIds);
                });
            },
            manageGroups: (group) => {
                set((state) => {
                    // Clear active social profile
                    state.activeSocialProfileId = null;
                    deleteActiveProfileIdLocalStorage();

                    // Clear active multi selection profiles
                    state.activeMultiSelectionProfiles = null;
                    deleteActiveMultiProfileIdsLocalStorage();

                    // Set active social group
                    state.activeGroupId = group.id;
                    setActiveGroupIdLocalStorage(group.id);
                });
            },
            updateActiveSocialProfileId: (profileId) => {
                set((state) => {
                    state.activeSocialProfileId = profileId;
                    setActiveProfileIdLocalStorage(profileId);
                });
            },
            updateActiveGroupId: (groupId) => {
                set((state) => {
                    state.activeGroupId = groupId;
                    setActiveGroupIdLocalStorage(groupId);
                });
            },
            updateActiveMultiProfileIds: (profileIds) => {
                set((state) => {
                    state.activeMultiSelectionProfiles = {
                        socialProfileIds: profileIds
                    };
                    setActiveMultiProfileIdsLocalStorage(profileIds);
                });
            },
            resetActiveSocialProfile: () => {
                set((state) => {
                    state.activeSocialProfileId = null;
                    deleteActiveProfileIdLocalStorage();
                });
            },
            resetActiveGroup: () => {
                set((state) => {
                    state.activeGroupId = null;
                    deleteActiveGroupIdLocalStorage();
                });
            }
        }))
    );
};
