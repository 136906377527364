import { createStore } from 'zustand/vanilla';
import { immer } from 'zustand/middleware/immer';

export type LoggedInStoreStateType = {
    isLoggedIn: boolean;
};

export type LoggedInStoreActionsType = {
    updateIsLoggedIn: (value: boolean) => void;
};

export const defaultInitState: LoggedInStoreStateType = {
    isLoggedIn: false
};

export type LoggedInStoreType = LoggedInStoreStateType & LoggedInStoreActionsType;

export const createLoggedInGlobalStore = (initState: LoggedInStoreStateType = defaultInitState) => {
    return createStore<LoggedInStoreType>()(
        immer((set) => ({
            ...initState,
            updateIsLoggedIn: (value) => {
                set((state) => {
                    state.isLoggedIn = value;
                });
            }
        }))
    );
};
