import { useCallback, useMemo } from 'react';
import {
    useGlobalInfo,
    UPDATE_ACTIVE_SOCIAL_PROFILE,
    UPDATE_ACTIVE_SOCIAL_PROFILE_IN_GROUP,
    CLEAR_GROUP_INFO
} from '@/hooks/context/useGlobalInfo';

import type { SocialProfileType } from '@/types/socialProfileType';

// Hooks
import useSPGroups from '@/hooks/swr/group/useSPGroups';
import useSocialProfiles from '@/hooks/swr/useSocialProfiles';
import useActiveTreeStructure from '@/utils/hooks/tree-structure/useActiveTreeStructure';

// Store
import { useProfilesStore } from '@/utils/zustand/providers/profiles-provider';

const useOldActiveSocialProfile = () => {
    const { globalInfo, dispatch } = useGlobalInfo();

    const { socialProfiles } = useSocialProfiles({
        revalidateOnMount: false,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });

    const activeSocialProfileId = useMemo(() => {
        if (globalInfo.group.activeGroup) {
            return globalInfo.group.activeSocialProfile?.id;
        }
        if (!globalInfo.group.activeGroup) {
            return globalInfo.social_profiles.active?.id;
        }
        return null;
    }, [
        globalInfo.social_profiles.active,
        globalInfo.group.activeGroup,
        globalInfo.group.activeSocialProfile
    ]);

    // After a mutation of the socialProfiles useSocialProfiles, activeSocialProfile can be out of sync.
    // The temporary fix chosen is the use of the .find below.
    // If we notice performance issues from looping over the socialProfiles, we should find a way to always sync usSocialProfile and useActiveSocialProfile.
    // If we don't notice any performance issues, we should replace the object activeSocialProfile by the int activeSocialProfileId in globalInfo.
    const activeSocialProfile: SocialProfileType = useMemo(() => {
        if (socialProfiles?.length > 0 && activeSocialProfileId && !globalInfo.group.activeGroup) {
            return socialProfiles.find((sp) => sp.id === activeSocialProfileId);
        }
        if (
            globalInfo.group.activeGroup &&
            globalInfo.group.activeGroup.social_profiles?.length &&
            activeSocialProfileId
        ) {
            return globalInfo.group.activeGroup.social_profiles.find(
                (sp) => sp.id === activeSocialProfileId
            );
        }
        return null;
    }, [socialProfiles, activeSocialProfileId, globalInfo.group.activeGroup]);

    const setActiveSocialProfile = useCallback(
        (social_profile: SocialProfileType) => {
            if (activeSocialProfileId !== social_profile.id && !globalInfo.group.activeGroup) {
                dispatch({
                    type: UPDATE_ACTIVE_SOCIAL_PROFILE,
                    payload: social_profile
                });
                localStorage.setItem('ico-social-profiles-active', JSON.stringify(social_profile));
            }
            if (activeSocialProfileId !== social_profile.id && globalInfo.group.activeGroup) {
                if (
                    !globalInfo.group.activeGroup?.social_profiles?.find(
                        (profile) => profile.id === social_profile.id
                    )
                ) {
                    dispatch({ type: CLEAR_GROUP_INFO });
                } else {
                    dispatch({
                        type: UPDATE_ACTIVE_SOCIAL_PROFILE_IN_GROUP,
                        payload: globalInfo.group.activeGroup.social_profiles?.find(
                            (socialProfile) => socialProfile.id === social_profile.id
                        )
                    });
                    localStorage.setItem(
                        'ico-group-profiles-active',
                        JSON.stringify(social_profile)
                    );
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeSocialProfileId, globalInfo.group.activeGroup]
    );

    return {
        oldActiveSocialProfile: activeSocialProfile,
        setOldActiveSocialProfile: setActiveSocialProfile
    };
};

const useNewActiveSocialProfile = () => {
    const {
        activeSocialProfileId,
        activeGroupId,
        activeMultiSelectionProfiles,
        updateActiveMultiProfileIds,
        updateActiveSocialProfileId
    } = useProfilesStore((state) => state);

    const { activatedSocialProfiles } = useSocialProfiles({
        revalidateIfStale: false
    });
    const { activatedSpGroups } = useSPGroups({
        revalidateIfStale: false
    });

    const activeSocialProfile: SocialProfileType = useMemo(() => {
        if (activatedSocialProfiles?.length && activeSocialProfileId) {
            return activatedSocialProfiles.find(
                (socialProfile) => socialProfile.id === activeSocialProfileId
            );
        }

        return null;
    }, [activatedSocialProfiles, activeSocialProfileId]);

    const setActiveSocialProfile = useCallback(
        (socialProfile: SocialProfileType) => {
            updateActiveSocialProfileId(socialProfile.id);
            if (activeGroupId) {
                const activeGroup = activatedSpGroups.find((group) => group.id === activeGroupId);
                const activatedSocialProfilesGroup = activeGroup.social_profiles.filter(
                    (profile) => profile.active
                );
                const hasSocialProfileInGroup = activatedSocialProfilesGroup.find(
                    (profile) => profile.active && profile.id === socialProfile.id
                );

                if (!hasSocialProfileInGroup) {
                    const groupProfilesId = activatedSocialProfilesGroup.map(
                        (profile) => profile.id
                    );
                    updateActiveMultiProfileIds([...groupProfilesId, socialProfile.id]);
                }
                return;
            }

            if (!activeMultiSelectionProfiles?.socialProfileIds.includes(socialProfile.id)) {
                updateActiveMultiProfileIds([
                    ...activeMultiSelectionProfiles.socialProfileIds,
                    socialProfile.id
                ]);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeGroupId, activeMultiSelectionProfiles?.socialProfileIds, activatedSpGroups]
    );

    return {
        newActiveSocialProfile: activeSocialProfile,
        setNewActiveSocialProfile: setActiveSocialProfile
    };
};

const useActiveSocialProfile = () => {
    const { isTreeStructureActivated } = useActiveTreeStructure();

    // To remove after tree structure deployment
    const { oldActiveSocialProfile, setOldActiveSocialProfile } = useOldActiveSocialProfile();

    const { newActiveSocialProfile, setNewActiveSocialProfile } = useNewActiveSocialProfile();

    return {
        activeSocialProfile: isTreeStructureActivated
            ? newActiveSocialProfile
            : oldActiveSocialProfile,
        setActiveSocialProfile: isTreeStructureActivated
            ? setNewActiveSocialProfile
            : setOldActiveSocialProfile
    };
};

export default useActiveSocialProfile;
