import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { dequal } from 'dequal';

type UtmDataType = {
    utm_source: string;
    utm_campaign: string;
    utm_medium: string;
    utm_term: string;
    utm_content: string;
    referrer_url: string;
    gclid: string;
    campaign_id: string;
    ad_group_id: string;
    ad_id: string;
};

const UtmData: Partial<UtmDataType> = {};
export function useUtmDataCookie() {
    const router = useRouter();
    const {
        utm_source,
        utm_campaign,
        utm_medium,
        utm_term,
        utm_content,
        gclid,
        campaign_id,
        ad_group_id,
        ad_id
    } = router.query;

    useEffect(() => {
        const utm_params = [
            { utm_source },
            { utm_campaign },
            { utm_medium },
            { utm_term },
            { utm_content },
            { gclid },
            { campaign_id },
            { ad_group_id },
            { ad_id }
        ].filter((param) => Object.values(param)[0]);

        if (!utm_params?.length) {
            return;
        }

        for (let i = 0; i < utm_params.length; i++) {
            UtmData[Object.keys(utm_params[i])[0]] = Object.values(utm_params[i])[0] as string;
        }

        if (utm_source) {
            Cookies.set('utm_source', utm_source, {
                domain: '.iconosquare.com',
                expires: 365
            });
        }

        if (!dequal(UtmData, {})) {
            if (!(typeof window === 'undefined')) {
                const referrer = document.referrer;
                if (referrer && !referrer?.includes('.iconosquare.com')) {
                    UtmData.referrer_url = referrer;
                }
            }
            Cookies.set('utm_data', JSON.stringify(UtmData), {
                domain: '.iconosquare.com',
                expires: 365
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [utm_source, utm_campaign, utm_medium, utm_term, utm_content]);
}
