import useSWR from 'swr';
import { useMemo } from 'react';

// Hooks
import useUser from '@/hooks/swr/useUser';
import useSocialProfilesAuthStatus from '@/hooks/swr/useSocialProfilesAuthStatus';

// Utils
import { get } from '@/utils/client';

// Types
import type { ErrorCustom } from '@/utils/client';
import type { SWRConfiguration } from 'swr';
import type { SocialProfileType } from '@/types/socialProfileType';

// Libs
import { checkNetworkInList } from '@/lib/socialprofiles/checkICurrenNetwork';
import { FACEBOOK, INSTAGRAM } from '@/lib/socialProfile';

// API Endpoints
import { USER_SOCIAL_PROFILES } from '@/routes/apiEndpoints';

export type MutateSocialProfilesType = ReturnType<typeof useSocialProfiles>['mutateSocialProfiles'];

type SocialProfileTypeWithoutAuthStatus = Omit<SocialProfileType, 'auth_status'>;

const useSocialProfiles = (
    options?: SWRConfiguration<SocialProfileTypeWithoutAuthStatus[], ErrorCustom>
) => {
    const { user } = useUser();
    const { socialProfilesAuthStatus } = useSocialProfilesAuthStatus({ revalidateOnMount: false });
    const {
        data,
        mutate: mutateSocialProfiles,
        error: socialProfilesError,
        isLoading
    } = useSWR<SocialProfileTypeWithoutAuthStatus[], ErrorCustom>(
        user && user?.sign_up_step !== 'user_created' ? USER_SOCIAL_PROFILES : null,
        (url) => get(url).then((res) => res.data),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            ...options
        }
    );

    const {
        socialProfiles,
        activatedSocialProfiles,
        deactivatedSocialProfiles,
        errorAuthDataAccessSocialProfiles,
        errorAuthOtherSocialProfiles,
        errorAuthSchedulerPermissions,
        errorAuthOtherSchedulerPermissions,
        errorAuthTeamSchedulerPermissions,
        errorAuthTeamOtherSchedulerPermissions
    } = useMemo(() => {
        const socialProfilesData =
            data?.map((profile) => ({
                ...profile,
                auth_status: socialProfilesAuthStatus?.[profile.id] ?? {}
            })) ?? [];
        const activatedSocialProfiles = [];
        const deactivatedSocialProfiles = [];
        const errorAuthDataAccessSocialProfiles = [];
        const errorAuthOtherSocialProfiles = [];
        const errorAuthSchedulerPermissions = [];
        const errorAuthOtherSchedulerPermissions = [];
        const errorAuthTeamSchedulerPermissions = [];
        const errorAuthTeamOtherSchedulerPermissions = [];

        socialProfilesData.forEach((profile) => {
            if (profile?.active) {
                activatedSocialProfiles.push(profile);
                if (checkNetworkInList(profile.social_network_type, [INSTAGRAM, FACEBOOK])) {
                    if (profile?.auth_status?.type === 'error') {
                        errorAuthDataAccessSocialProfiles.push(profile);
                    }
                    if (profile?.auth_status?.error === 'user_re_auth_scheduler_permissions') {
                        errorAuthSchedulerPermissions.push(profile);
                    }
                    if (profile?.auth_status?.error === 'user_team_scheduler_permissions') {
                        errorAuthTeamSchedulerPermissions.push(profile);
                    }
                    return;
                }

                if (profile?.auth_status?.type === 'error') {
                    errorAuthOtherSocialProfiles.push(profile);
                }
                if (profile?.auth_status?.error === 'user_re_auth_scheduler_permissions') {
                    errorAuthOtherSchedulerPermissions.push(profile);
                }
                if (profile?.auth_status?.error === 'user_team_scheduler_permissions') {
                    errorAuthTeamOtherSchedulerPermissions.push(profile);
                }
                return;
            }
            deactivatedSocialProfiles.push(profile);
        });

        return {
            socialProfiles: socialProfilesData,
            activatedSocialProfiles,
            deactivatedSocialProfiles,
            errorAuthDataAccessSocialProfiles,
            errorAuthOtherSocialProfiles,
            errorAuthSchedulerPermissions,
            errorAuthOtherSchedulerPermissions,
            errorAuthTeamSchedulerPermissions,
            errorAuthTeamOtherSchedulerPermissions
        };
    }, [data, socialProfilesAuthStatus]);

    return {
        socialProfiles,
        activatedSocialProfiles,
        deactivatedSocialProfiles,
        errorAuthDataAccessSocialProfiles,
        errorAuthOtherSocialProfiles,
        errorAuthSchedulerPermissions,
        errorAuthOtherSchedulerPermissions,
        errorAuthTeamSchedulerPermissions,
        errorAuthTeamOtherSchedulerPermissions,
        mutateSocialProfiles,
        socialProfilesError,
        isLoading
    };
};

export default useSocialProfiles;
