// Types
import type { ProfilesStoreStateType } from '@/utils/zustand/stores/profiles-store';

/**
 * Active group id local storage
 */
const activeGroupIdLocalStorageKey = 'local-storage-active-group-id';

export function setActiveGroupIdLocalStorage(groupId: ProfilesStoreStateType['activeGroupId']) {
    window.localStorage.setItem(activeGroupIdLocalStorageKey, JSON.stringify(groupId));
}

export function getActiveGroupIdLocalStorage(): ProfilesStoreStateType['activeGroupId'] {
    return JSON.parse(window.localStorage.getItem(activeGroupIdLocalStorageKey));
}

export function deleteActiveGroupIdLocalStorage() {
    window.localStorage.removeItem(activeGroupIdLocalStorageKey);
}

/**
 * Active profile id local storage
 */
const activeProfileIdLocalStorageKey = 'local-storage-active-profile-id';

export function setActiveProfileIdLocalStorage(
    profileId: ProfilesStoreStateType['activeSocialProfileId']
) {
    window.localStorage.setItem(activeProfileIdLocalStorageKey, JSON.stringify(profileId));
}

export function getActiveProfileIdLocalStorage(): ProfilesStoreStateType['activeSocialProfileId'] {
    return JSON.parse(window.localStorage.getItem(activeProfileIdLocalStorageKey));
}

export function deleteActiveProfileIdLocalStorage() {
    window.localStorage.removeItem(activeProfileIdLocalStorageKey);
}

/**
 * Active multi profile ids local storage
 */
const activeMultiProfileIdsLocalStorageKey = 'local-storage-active-multi-profile-ids';

export function setActiveMultiProfileIdsLocalStorage(
    profileIds: ProfilesStoreStateType['activeMultiSelectionProfiles']['socialProfileIds']
) {
    window.localStorage.setItem(activeMultiProfileIdsLocalStorageKey, JSON.stringify(profileIds));
}

export function getActiveMultiProfileIdsLocalStorage(): ProfilesStoreStateType['activeMultiSelectionProfiles']['socialProfileIds'] {
    return JSON.parse(window.localStorage.getItem(activeMultiProfileIdsLocalStorageKey));
}

export function deleteActiveMultiProfileIdsLocalStorage() {
    window.localStorage.removeItem(activeMultiProfileIdsLocalStorageKey);
}
