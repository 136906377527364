'use client';

import { createContext, useRef, useContext } from 'react';
import { useStore } from 'zustand';

import { createSignUpStore } from '@/utils/zustand/stores/sign-up-store';

// Types
import type { StoreApi } from 'zustand';
import type { SignUpStoreType } from '@/utils/zustand/stores/sign-up-store';

const SignUpStoreContext = createContext(null);

export type SignUpStoreProviderProps = {
    children: React.ReactNode;
};

export const SignUpStoreProvider = ({ children }: SignUpStoreProviderProps) => {
    const storeRef = useRef<StoreApi<SignUpStoreType>>(null); // Add null as initial value

    if (!storeRef.current) {
        storeRef.current = createSignUpStore();
    }

    return (
        <SignUpStoreContext.Provider value={storeRef.current}>
            {children}
        </SignUpStoreContext.Provider>
    );
};

export const useSignUpStore = <T,>(selector: (store: SignUpStoreType) => T): T => {
    const signUpStoreContext = useContext(SignUpStoreContext);

    if (!signUpStoreContext) {
        throw new Error(`useSignUpStore must be use within SignUpStoreProvider`);
    }

    return useStore(signUpStoreContext, selector);
};
