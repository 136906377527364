export const LOGIN = '/login';
export const REGISTER = '/register';
export const INVITATION_REGISTER = `/user/invitations/%IID%/register`;
export const LOGOUT = '/logout';
export const WEBFLOW_ARTICLES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/articles`;
export const USER = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user`;
export const USER_MARKETING = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/marketing`;
export const USER_PERMISSIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/permissions`;
export const USER_PENDO = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/pendo`;
export const USER_PROFILE_INFOS = '/user/profile-information';
export const RESET_PASSWORD = '/reset-password';
export const FORGOT_PASSWORD = '/forgot-password';
export const UPDATE_PASSWORD = '/user/password';
export const EMAIL_VERIFICATION = '/email/verification-notification';
export const EMAIL_VALIDATE_USER = '/email/validate/user/%USERID%';
export const SETTINGS_PLATFORM = `/v${process.env.NEXT_PUBLIC_API_VERSION}/platform-settings`;
export const SETTINGS_DATA_ACCESS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/settings/data-access`;
export const SETTINGS_DATA_ACCESS_LOGOUT = `/v${process.env.NEXT_PUBLIC_API_VERSION}/settings/data-access/logout`;
export const UPLOAD_MEDIA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/upload/media`;
export const UPLOAD_MEDIA_DOCUMENT = `/v${process.env.NEXT_PUBLIC_API_VERSION}/document/upload`;
export const MEDIA_DOCUMENT = `/v${process.env.NEXT_PUBLIC_API_VERSION}/document`;
export const USER_API_PROFILES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/api-profiles`;
export const USER_SOCIAL_PROFILES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/social-profiles`;
export const USER_SOCIAL_PROFILES_AUTH_STATUS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/social-profiles/auth-status`;
export const USER_SOCIAL_PROFILES_GROUPS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/social-profiles/groups`;
export const USER_SOCIAL_PROFILE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/social-profiles/%SPID%`;
export const SOCIAL_PROFILE_PERMISSIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/social-profiles/%SPID%/permissions`;
export const ACTIVATE_SOCIAL_PROFILE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/active`;
export const DEACTIVATE_SOCIAL_PROFILE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/inactive`;
export const RELAUNCH_SOCIAL_PROFILE_DATA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/relaunch`;
export const ANALYTICS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/analytics`;
export const GROUP_ANALYTICS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/groups/%GROUP_ID%/analytics`;
export const SOCIAL_PROFILE_EXPORTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/exports/%TYPE%`;
export const ANALYTICS_MULTIPLE_PROFILES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profiles/analytics`;
export const SIGNUP_NEXT_STEP = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/signup-next-step`;
export const MEDIA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/medias/%mediaID%`;
export const MEDIAS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/medias`;
export const GROUP_MEDIA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/groups/%GROUP_ID%/medias/%mediaID%`;
export const GROUP_MEDIAS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/groups/%GROUP_ID%/medias`;
export const HASHTAGS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/hashtags`;
export const STORY = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/stories/%mediaID%`;
export const STORIES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/stories`;
export const ALBUMS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/albums`;
export const ALBUMS_COMPARATIVE_TABLE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/albums-overview`;
export const ALBUMS_NO_DETAILS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/albums-no-details`;
export const ALBUM = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/albums/%ALBUM_ID%`;
export const ALBUM_MEDIA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/albums/%ALBUM_ID%/medias`;
export const TRANSLATE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/translate`;
export const SCHEDULER_EVENTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/events`;
export const SCHEDULER_POSTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/scheduler-posts`;
export const SCHEDULER_POST = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/scheduler-posts/%postId%`;
export const SCHEDULER_POST_CONVERT = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/scheduler-post/%postId%/convert`;
export const SCHEDULER_POST_CONVERT_STATUS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/scheduler-post/%postId%/status`;
export const SCHEDULER_MASTER_POSTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/scheduler-master-posts/%masterId%`;
export const SCHEDULER_POST_NOTES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/scheduler-posts/%postID%/scheduler-post-notes`;
export const SCHEDULER_EDIT_POST_NOTES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/scheduler-posts/%postID%/scheduler-post-notes/%NOTEID%`;
export const SCHEDULER_POST_DATA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/post-data`;
export const SCHEDULER_TIME_SLOTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/time-slots`;
export const SCHEDULER_PREVIEW_POSTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/preview-scheduler-posts`;
export const SCHEDULER_PROFILE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/scheduler-profile`;
export const SCHEDULER_CROSS_POST_PROFILES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/scheduler-profiles`;
export const SCHEDULER_SEND_NOTIFICATION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/push-confirmation-notification`;
export const VALIDATE_AUTO_PUBLISHING_NOTIFICATION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/validate-auto-publishing-notification`;
export const GET_DASHBOARD_LIST = `/v${process.env.NEXT_PUBLIC_API_VERSION}/dashboards`;
export const CREATE_DASHBOARD = `/v${process.env.NEXT_PUBLIC_API_VERSION}/dashboards`;
export const GET_DASHBOARD = `/v${process.env.NEXT_PUBLIC_API_VERSION}/dashboards/%dashboardId%`;
export const UPDATE_DASHBOARD = `/v${process.env.NEXT_PUBLIC_API_VERSION}/dashboards/%dashboardId%`;
export const GET_DASHBOARD_PDF_DATA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/dashboards/%dashboardId%/report-data`;
export const DELETE_DASHBOARD = `/v${process.env.NEXT_PUBLIC_API_VERSION}/dashboards/%dashboardId%`;
export const DUPLICATE_DASHBOARD = `/v${process.env.NEXT_PUBLIC_API_VERSION}/dashboards/%dashboardId%/duplicate`;
export const CHECK_NAME_DASHBOARD = `/v${process.env.NEXT_PUBLIC_API_VERSION}/dashboards/check-name`;
export const SHARE_DASHBOARD = `/v${process.env.NEXT_PUBLIC_API_VERSION}/dashboards/%dashboardId%/invite`;
export const GET_DASHBOARD_INVITES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/dashboards/invitations`;
export const PROCESS_DASHBOARD_INVITATION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/dashboards/%dashboardId%/invite/process`;
export const DEVICE_TOKEN = `/v${process.env.NEXT_PUBLIC_API_VERSION}/device-token`;
export const LABELS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/labels/%SPID%`;
export const BILLING_SUBSCRIPTION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/billing/subscription`;
export const BILLING_PRICES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/billing/prices`;
export const EXTEND_TRIAL = `/v${process.env.NEXT_PUBLIC_API_VERSION}/billing/extend-trial`;
export const COMMENTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/medias/%mediaID%/comments`;
export const COMMENTS_EXPORT = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/medias/%mediaID%/comments/export`;
export const COMMENT_REPLY = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/medias/%mediaID%/comments/%commentID%`;
export const COMMENT_ADD = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/medias/%mediaID%/comments`;
export const COMMENT_DELETE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/medias/%mediaID%/comments/%commentID%`;
export const COMMENT_REPLIES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/medias/%mediaID%/comments/%commentID%`;
export const COMMENT_MARK_AS_READ = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/comments/mark-as-read`;
export const USER_INVITATIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/invitations`;
export const RESEND_USER_INVITATION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/invitations/%IID%/resend`;
export const SEND_BATCH_USER_INVITATIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/invitations/batch`;
export const COMMENTS_TRACKER = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/comments`;
export const USER_TEAM_MEMBERS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/team/users`;
export const COLLABORATION_SHARING_LINK = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaboration/sharing-settings/social-profile/%SPID%/link`;
export const COLLABORATION_SHARING_LINK_GROUP = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaboration/sharing-settings/group/%GROUP_ID%/link`;
export const COLLABORATION_SECURE_LINK = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaboration/sharing-settings/social-profile/%SPID%/link/%LINK_ID%/secure`;
export const COLLABORATION_SECURE_LINK_GROUP = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaboration/sharing-settings/group/%GROUP_ID%/link/%LINK_ID%/secure`;
export const COLLABORATION_NOTIFICATIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaboration/notifications`;
export const COLLABORATION_NOTIFICATIONS_MARK_AS_READ = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaboration/notifications/mark-as-read`;
export const COLLABORATE_AREA_LOGIN_INFOS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/login/infos`;
export const COLLABORATE_AREA_USER = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/user`;
export const COLLABORATE_AREA_LOGIN = `/collaborate-area/%LINK%/login`;
export const COLLABORATE_AREA_LOGOUT = `/collaborate-area/%LINK%/logout`;
export const COLLABORATE_AREA_GET_POSTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/scheduler-posts`;
export const COLLABORATE_AREA_GET_FEED_POSTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/preview-scheduler-posts`;
export const COLLABORATE_AREA_UPCOMING_POSTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/side-preview-scheduler-posts`;
export const COLLABORATE_AREA_GET_POSTS_TO_VALIDATE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/validate-scheduler-posts`;
export const COLLABORATE_AREA_GET_EVENTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/events`;
export const COLLABORATE_AREA_GET_IG_INFOS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/scheduler-profile`;
export const COLLABORATE_AREA_GET_POST = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/scheduler-posts/%SCHEDULERID%`;
export const COLLABORATE_AREA_ADD_GET_NOTES = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/scheduler-posts/%SCHEDULERID%/scheduler-post-notes`;
export const COLLABORATE_AREA_EDIT_DELETE_NOTE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/scheduler-posts/%SCHEDULERID%/scheduler-post-notes/%NOTEID%`;
export const COLLABORATE_AREA_UPDATE_POST_STATUS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/collaborate-area/%LINK%/scheduler-posts/%SCHEDULERID%/update-status`;
export const LIBRARY_MEDIA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/library-medias`;
export const INDUSTRY_BENCHMARK = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/industry-benchmark`;
export const CREATE_MEDIA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/library-medias`;
export const PUT_MEDIA_LIBRARY = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%socialProfil%/library-medias/%libraryMedia%`;
export const DELETE_LIBRARY_MEDIA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/library-medias`;
export const DETACH_TAGS_FROM_MEDIA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%socialProfile%/library-medias/detach-tags`;
export const ADD_TAG = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%socialProfile%/library-tags`;
export const DELETE_TAG = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%socialProfile%/library-tags/%libraryTag%`;
export const LINK_TAG_TO_MEDIA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%socialProfile%/library-medias/attach-tags`;
export const URL_AVAILABLE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/url-available`;
export const COMPETITOR = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/competitors/%competitorID%`;
export const COMPETITOR_ANALYTICS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/competitors/analytics/%competitorID%`;
export const COMPETITORS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/competitors`;
export const COMPETITORS_ANALYTICS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/competitors/analytics`;
export const COMPETITORS_SEARCH = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/competitors/search`;
export const LOCATIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/search-locations`;
export const CONVERSATIONS_PERMISSIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/social-profiles/%SPID%/dm-activation-status`;
export const CONVERSATIONS_ACTIVATE_WEBHOOKS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/social-profiles/%SPID%/activate-instagram-dm-webhook`;
export const CONVERSATIONS_LIST = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%socialProfile%/conversations`;
export const CONVERSATIONS_DETAILS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%socialProfile%/conversations/%conversationId%?with=messages`;
export const CONVERSATIONS_SET_STATUS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%socialProfile%/conversations/%conversationId%/status`;
export const SEND_NEW_MESSAGE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%socialProfile%/conversations/%conversationId%/messages`;
export const LIKE_MESSAGE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%socialProfile%/conversations/%conversation%/messages/%message%/reaction/%reaction%`;
export const URL_METADATA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/scrape-url`;
export const CROP_IMAGE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/crop-image`;
export const VIDEO_THUMBNAILS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/thumbnails`;
export const SEARCH_MENTIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/search-mentions`;
export const MENTIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/mentions`;
export const MENTIONS_MARK_AS_READ = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/mentions/mark-as-read`;
export const MENTION_REPLY = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/mentions/%mentionID%`;
export const GET_MENTIONS_FEED = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/feeds/mentions`;
export const GET_MENTIONS_FEED_MEDIA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/feeds/mentions/%mediaID%`;
export const REPLY_MENTION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/mentions/%mentionID%`;
export const CAPTION_LIST = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/captions-hashtag-lists`;
export const CAPTION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/captions-hashtag-lists/%CAPTIONID%`;
export const BILLING_RECEIPTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/billing/receipts`;
export const CANCEL_SUBSCRIPTION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/billing/cancel`;
export const CANCEL_SUBSCRIPTION_CANCELLATION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/billing/cancel-cancellation`;
export const CANCEL_SUBSCRIPTION_DOWNGRADE = `/v${process.env.NEXT_PUBLIC_API_VERSION}/billing/cancel-downgrade`;
export const FEEDS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/feeds`;
export const REMOVE_FEED = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/feeds/%FEED_ID%`;
export const FEEDS_POSTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/feeds/%FEED_ID%`;
export const INSTAGRAM_HASHTAGS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/instagram-hashtags`;
export const SEARCH_INSTAGRAM_HASHTAG = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/instagram-hashtags/search`;
export const ADD_INSTAGRAM_HASHTAG = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/instagram-hashtags`;
export const REMOVE_INSTAGRAM_HASHTAG = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/instagram-hashtags/%hashtagID%`;
export const GET_INSTAGRAM_HASHTAGS_ANALYTICS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/instagram-hashtags/analytics`;
export const GET_INSTAGRAM_HASHTAG_ANALYTICS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/instagram-hashtags/analytics/%hashtagID%`;
export const GET_INSTAGRAM_HASHTAG_TOP_POSTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/instagram-hashtags/%hashtagID%/medias`;
export const MARK_AS_FAVORITE_HASHTAG = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/instagram-hashtags/%hashtagID%/mark-as-favorite`;
export const GET_ALL_HASHTAGS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/instagram-hashtags`;
export const FEEDS_SEARCH = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/feeds/%FEED_ID%/search`;
export const REPORTS_SCHEDULED = `/v${process.env.NEXT_PUBLIC_API_VERSION}/reports/schedulers`;
export const REPORT_SCHEDULED = `/v${process.env.NEXT_PUBLIC_API_VERSION}/reports/scheduler/%id%`;
export const EDIT_REPORT_SCHEDULED = `/v${process.env.NEXT_PUBLIC_API_VERSION}/reports/scheduler/edit`;
export const REPORTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/reports`;
export const CREATE_REPORT = `/v${process.env.NEXT_PUBLIC_API_VERSION}/reports/create`;
export const DELETE_REPORT = `/v${process.env.NEXT_PUBLIC_API_VERSION}/reports/scheduler`;
export const DOWNLOAD_REPORT = `/v${process.env.NEXT_PUBLIC_API_VERSION}/pdf/data`;
export const SLOTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/slots`;
export const BILLING_LINK = `/v${process.env.NEXT_PUBLIC_API_VERSION}/billing/link`;
export const BILLING_ORDER = `/v${process.env.NEXT_PUBLIC_API_VERSION}/billing/order`;
export const BILLING_PAY = `/v${process.env.NEXT_PUBLIC_API_VERSION}/billing/pay`;
export const GET_DOWNGRADE_FUNNEL_INFOS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/overconsumption`;
export const CLEAR_DOWNGRADE_FUNNEL_INFOS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/clear-overconsumption`;
export const REFERRAL_PROGRAM_INFOS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/referral-program/infos`;
export const RANDOM_COMMENT_PICKER_TOOL = `/v${process.env.NEXT_PUBLIC_API_VERSION}/random-comment-picker-tool/%MEDIA_ID%`;
export const NOTIFICATIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/notifications`;
export const NOTIFICATIONS_LAST_NEW = `/v${process.env.NEXT_PUBLIC_API_VERSION}/notifications/last-new`;
export const NOTIFICATIONS_MARK_AS_READ = `/v${process.env.NEXT_PUBLIC_API_VERSION}/notifications/mark-as-read`;
export const DELETE_NOTIFICATION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/notifications/delete`;
export const DELETE_ALL_READ_NOTIFICATIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/notifications/delete-all-read`;
export const SCHEDULER_AI_CAPTIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/captions/generate`;
export const GENERATE_AI_CAPTIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/captions/generate`;
export const SP_GROUPS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/groups`;
export const ONBOARDING_TASKS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/onboarding-tasks`;
export const ALL_SOCIAL_PROFILES_ANALYTICS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profiles/analytics/overview`;
export const PAYMENT_IN_PROGRESS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/billing/processing`;
export const INSPIRATION_IDEA_POOLS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/ideas`;
export const INSPIRATION_IDEA_TAGS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/ideas/tags`;
export const INSPIRATION_IDEA_POOL = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/idea-pool`;
export const PROFILE_ACTIVITY = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/activity`;
export const PROFILE_SUMMARY = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/summary`;
export const INSPIRATION_IDEA = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/ideas/%IDEA_ID%`;
export const INSPIRATION_IDEA_GENERATE_CAPTION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/ideas/%IDEA_ID%/caption`;
export const INSPIRATION_IDEA_SCHEDULE_POST = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/ideas/%IDEA_ID%/post`;
export const INSPIRATION_IDEA_POSTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/ideas/%IDEA_ID%/posts`;
export const INSPIRATION_IDEA_TAG = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/ideas/%IDEA_ID%/tags/%TAG_ID%`;
export const INSPIRATION_IDEA_POSITIONING = `/v${process.env.NEXT_PUBLIC_API_VERSION}/social-profile/%SPID%/idea-pool/positioning`;
export const USER_PUBLIC_TOKENS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/public/tokens`;
export const USER_UNLINK_GOOGLE_PROVIDER = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/provider/google`;
export const INTEGRATION_SUGGESTION = `/v${process.env.NEXT_PUBLIC_API_VERSION}/integrations`;
export const ZAPIER_PUBLIC_TOKEN = `/v${process.env.NEXT_PUBLIC_API_VERSION}/user/public/tokens/zapier`;
export const SCHEDULER_POST_SPECIFICATIONS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/scheduler-posts-specifications`;
export const MEDIA_UPLOAD = `/v${process.env.NEXT_PUBLIC_API_VERSION}/media/upload`;
export const MEDIA_STATUS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/media`;
export const CREATE_SCHEDULER_MASTER_POST = `/v${process.env.NEXT_PUBLIC_API_VERSION}/scheduler-master-posts`;
export const EDIT_SCHEDULER_MASTER_POST = `/v${process.env.NEXT_PUBLIC_API_VERSION}/scheduler-master-posts/%MASTER_ID%`;
export const CONFIRM_PRE_SAVED_POSTS = `/v${process.env.NEXT_PUBLIC_API_VERSION}/confirm-pre-saved-scheduler-posts`;
