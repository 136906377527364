'use client';

import { createContext, useRef, useContext } from 'react';
import { useStore } from 'zustand';

// Store
import { createLoggedInGlobalStore } from '@/utils/zustand/stores/logged-in-store';

// Types
import type { LoggedInStoreType } from '@/utils/zustand/stores/logged-in-store';
import type { StoreApi } from 'zustand';

const LoggedInStoreContext = createContext(null);

export type LoggedInStoreProviderProps = {
    children: React.ReactNode;
};

export const LoggedInStoreProvider = ({ children }: LoggedInStoreProviderProps) => {
    const storeRef = useRef<StoreApi<LoggedInStoreType>>(null); // Add null as initial value

    if (!storeRef.current) {
        storeRef.current = createLoggedInGlobalStore();
    }

    return (
        <LoggedInStoreContext.Provider value={storeRef.current}>
            {children}
        </LoggedInStoreContext.Provider>
    );
};

export const useLoggedInStore = <T,>(selector: (store: LoggedInStoreType) => T): T => {
    const loggedInStoreContext = useContext(LoggedInStoreContext);

    if (!loggedInStoreContext) {
        throw new Error(`useLoggedInStore must be use within LoggedInStoreProvider`);
    }

    return useStore(loggedInStoreContext, selector);
};
