import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import useUser from '@/utils/hooks/swr/useUser';

export function MixPanelInitialize() {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_API_KEY, {
        persistence: 'localStorage',
        ignore_dnt: true //bypass the do not track browser setting
    });

    return <MixPanelInitializeUser />;
}

function MixPanelInitializeUser() {
    const { user } = useUser();

    useEffect(() => {
        if (!user) {
            return;
        }
        mixpanel.identify(String(user.admin_id));

        /**
         * set user properties
         */
        mixpanel.people.set({
            $email: user.email,
            $user_id: user.id,
            $first_name: user?.first_name ?? '',
            $last_name: user?.last_name ?? ''
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(user)]);

    return null;
}
